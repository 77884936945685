import React, { Component } from "react";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.min.css";
import "./index.css";
import { Fragment } from 'react';
import MobileStoreButton from 'react-mobile-store-button';
import ScrollButton from './scrollButton';
const bannar = require("../resources/images/bannar.jpg");
const bodyImage = require("../resources/images/body_img.jpeg");
var appUrl = "https://play.google.com/store/apps/details?id=com.application.gmandi"
class IndexPage extends Component {




  render() {
    var appUrl = "https://play.google.com/store/apps/details?id=com.application.gmandi"
    return (
      <div style={{ boxSizing: 'border-box', margin: 0 }} >
        {/* Top band */}
        <div id="body" style={{ width: '100%' }} class="wrapper">

          {/* <div id="topbar"> 
        <div class="right">
        <ul class="nospace">
       <li><a style={{color:'#D3C086',fontSize:'18px'}} href="https://gmandi-portal.herokuapp.com/" target="_blank"><b>لاگ ان</b></a></li>
    <li><a style={{color:'#D3C086',fontSize:'18px'}} href="https://gmandi-portal.herokuapp.com/" target="_blank"><b style={{paddingLeft:'5px'}}>رجسٹر کریں</b></a></li>
      <li><a style={{color: 'green',fontSize:'18px',paddingLeft:'5px'}} class="fab fa-whatsapp" href="https://api.whatsapp.com/send?phone=923356969119&text=اسلام علیکم"><span style={{color:'#D3C086',fontSize:'18px'}}><b> 0335 6969 119</b></span></a> </li>
</ul>
</div>
   
          
          </div> */}

        </div>
        {/* end top band */}
        <nav id="navbar" >
          <div id="navbar-container" >

            <div  >

              <ul id="navbar-menu" class="row">
                {/* <li style={{listStyle:'none',paddingRight:'4%',paddingLeft:'5%'}}><a style={{color:'#D3C086',fontWeight:'bold',fontSize:'20px'}} href={appUrl} target="_blank">شیئر کریں</a></li> */}
                <li style={{ listStyle: 'none', paddingRight: '4%' }} ><a style={{ color: '#D3C086', fontWeight: 'bold', fontSize: '20px' }} href={appUrl} target="_blank">موبائل ایپ</a></li>

                <li style={{ listStyle: 'none', paddingRight: '4%' }} ><a style={{ color: '#D3C086', fontWeight: 'bold', fontSize: '20px' }} href="#contact">رابطہ</a></li>
                <li style={{ listStyle: 'none', paddingRight: '4%' }} ><a style={{ color: '#D3C086', fontWeight: 'bold', fontSize: '20px' }} href="#questions">سوال و جواب</a></li>
                {/* <li  style={{listStyle:'none',paddingRight:'4%',color:'#D3C086',fontWeight:'bold',fontSize:'20px'}}><Link style={{color:'#D3C086'}} to="/ProductRates"><button style={{backgroundColor:'none',border:'none',background:'none'}}> مصنوعات ریٹ </button></Link></li> */}
                <li style={{ listStyle: 'none', paddingRight: '4%' }}><a style={{ color: '#D3C086', fontWeight: 'bold', fontSize: '20px' }} href="#features">خصوصیات</a></li>
                <li style={{ listStyle: 'none', paddingRight: '18%' }}><a style={{ color: '#D3C086', fontWeight: 'bold', fontSize: '20px' }} href="#">مرکزی صفحہ</a></li>

                <li style={{ listStyle: 'none' }}><a style={{ color: '#D3C086', fontWeight: 'bold', fontSize: '20px' }} href="#">غلہ منڈی</a></li>
              </ul>
            </div>

            {/* </Navbar> */}
          </div>
        </nav>
        {/* Bannar Image  */}
        <div className="row mt-5">

          <div style={{ marginBottom: 0, height: '5%' }} className="col-md-12">
            <img style={{ backgroundPosition: 'center', backgroundSize: 'cover', width: '100%' }} src={bannar} alt="" />
          </div>
        </div>
        {/* Features  */}
        <div id="features" style={{ marginTop: 0 }} className="row col-md-12 mt-4">
          <div className="col-md-4">
            <br></br>
            <div className="col" >
              <div style={{ textAlign: 'center' }}>
                <br></br>
                <span style={{ color: '#353B77', fontSize: '25px' }}>نئے صارف بنائیں
                  &nbsp;&nbsp;
                  <i style={{ color: '#D3C086' }} class="fas fa-users"></i></span><br></br>
                <p style={{ color: '#C8AF5B' }}>
                  آپ اپنے ساتھ کام کرنے والے پارٹنر، ڈیٹا انٹری آپریٹر، مُنشی یا مینجر کا اکاونٹ بنا سکتے ہیں تا کہ وہ آپ کے کاروبار کے لین دین کا حساب جی منڈی ایپ میں ڈال سکیں۔</p>
              </div>
              <div style={{ textAlign: 'center' }}>
                <br></br>
                <span style={{ color: '#353B77', fontSize: '25px' }}>پارٹی&nbsp;&nbsp;
                  <i style={{ color: '#D3C086' }} class="fas fa-handshake"></i></span><br></br>
                <p style={{ color: '#C8AF5B' }}>آپ نئی پارٹی بنا سکتے ہیں جس کے ساتھ آپ لین دین کرتے ہیں۔ کسی بھی پارٹی کے ساتھ پُرانے لین دین کو آپ پارٹی بناتے وقت اُس کے اکاونٹ میں ڈال سکتے ہیں تا کہ نئے سارے لین دین کا حساب ایک جگہ ہی مل سکے۔

                </p></div>
            </div>
          </div>
          <div className="col-md-4" style={{ textAlign: 'center' }}>
            <span style={{ color: '#353B77', fontSize: '30px', fontWeight: 'bold' }}>خصوصیات
            </span><br></br><br></br>
            <div className="col" >

              <img src={bodyImage} className="img-fluid rounded h-75" alt="" style={{ width: '50%', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} />
            </div>
          </div>
          <div className="col-md-4">
            <br></br>
            <div className="col" >
              <div style={{ textAlign: 'center' }}>
                <br></br>
                <span style={{ color: '#353B77', fontSize: '25px' }}>صارف کو بلاک کرنا
                  &nbsp;&nbsp;
                  <i style={{ color: '#D3C086' }} class="fas fa-user-lock"></i></span><br></br>
                <p style={{ color: '#C8AF5B' }}>
                  آپ اپنے صارف کو بلاک بھِ کر سکتے ہیں پھر وہ لاگ ان نہیں کر سکے گا۔ بعد میں آپ اپنے صارف کو بحال بھی کر سکتے ہیں۔
                </p></div>
              <div style={{ textAlign: 'center' }}>
                <br></br>
                <span style={{ color: '#353B77', fontSize: '25px' }}>خرید و فروخت
                  &nbsp;&nbsp;
                  <i style={{ color: '#D3C086' }} class="fas fa-money-check-alt"></i></span><br></br>
                <p style={{ color: '#C8AF5B' }}>آپ کا کسانوں/بیوپاریوں یا رائس ملّوں کے ساتھ خرید و فروخت کا حساب کتاب انتہائی آسان اور جامع انداز میں کیا جاتا ہے۔ ملّوں کے ساتھ ہونے والے کنٹریکٹ/معاہدوں اور اُن معاہوں کے تحت کی گئی مال کی سپلائی اور اُس کی رقم کی ادائیگی کا مکمل حساب کتاب رکھا جاتا ہے- رقم کا لین دین: آپ اپنے ہر قسم کے رقم کے لین دین کیش،چیک،بینک ٹرانسفر یا آن لائن ٹرانسفر کا مکمل حساب بھی رکھ سکتے ہیں اور اس لین دین کو پارٹی کے اکاونٹ میں خود کار طریقے سے فورا ڈیبٹ/کریڈٹ کیا جاتا ہے تا کہ ہر پارٹی کا اکاونٹ موجودہ وقت میں مکمل معلومات فراہم کرے۔
                </p></div>
            </div>
          </div>
        </div>
        {/* More Features  */}
        <div id="moreFeatures" style={{ marginTop: 0, marginBottom: '15px' }} className="row col-md-12 mt-5">
          <div className="col-md-8" >
            <span style={{ color: '#353B77', fontSize: '30px', fontWeight: 'bold', float: 'right', paddingRight: '15%' }}>مزید خصوصیات</span>
            <br></br><br></br>
            <div className="col" >

              <div style={{ textAlign: 'center' }}>
                <br></br>
                <span style={{ color: '#353B77', float: 'right', fontSize: '25px' }}>پارٹی اکاونٹ
                  &nbsp;&nbsp;
                  <i style={{ color: '#D3C086' }} class="fas fa-handshake"></i></span><br></br><br></br>
                <p style={{ color: '#C8AF5B' }}>
                  آپ جیسے جیسے پارٹیوں کے ساتھ لین دین کرتے رہتے ہیں اُن کے اکاونٹ میں ڈیبٹ/کریڈٹ ہوتا رہتا ہے اور آپ کسی بھی وقت کسی بھی پارٹی کے پچھلے لین دین کو تفصیل کے ساتھ دیکھ سکتے ہیں
                  <br></br>نوٹ: آپ اپنے پرانے کھاتے بہت آسانی سے غلّہ منڈی سسٹممیں لا سکتے ہیں کیونکہ آپ پارٹی اکاونٹ بناتے وقت پچھلا بیلنس بھی ڈالتے ہیں تا کہ آپ مکمل طور پہ اس پارٹی کے لین دین کی جی منڈی سے معلومات لے سکیں اور کاغذی یا پُرانے کھاتوں سے نئے آن لائن اور جدید کھاتوں پہ منتقل ہو سکیں۔
                </p>
              </div>
              <div style={{ textAlign: 'center' }}>

                <span style={{ float: 'right', color: '#353B77', fontSize: '25px' }}>رقم کا لین دین &nbsp;&nbsp;
                  <i style={{ color: '#D3C086' }} class="fas fa-money-check-alt"></i></span><br></br><br></br>
                <p style={{ color: '#C8AF5B' }}>آپ اپنے ہر قسم کے رقم کے لین دین کیش،چیک،بینک ٹرانسفر یا آن لائن ٹرانسفر کا مکمل حساب بھی رکھ سکتے ہیں اور اس لین دین کو پارٹی کے اکاونٹ میں خود کار طریقے سے فورا ڈیبٹ/کریڈٹ کیا جاتا ہے تا کہ ہر پارٹی کا اکاونٹ موجودہ وقت میں مکمل معلومات فراہم کرے۔

                </p></div>
              <div style={{ float: 'right' }}>
                <br></br>
                <span style={{ float: 'right', color: '#353B77', fontSize: '25px' }}>رسیدوں کا تبادلہ&nbsp;&nbsp;
                  <i style={{ color: '#D3C086' }} class="fas fa-file-contract"></i></span><br></br><br></br>
                <p style={{ color: '#C8AF5B' }}>آپ اپنی رسیدیں بذریعہ ایس ایم ایس یا واٹس ایپ اپنے کاروباری ساتھیوں کے ساتھ شیئر کر سکتے ہیں۔
                </p></div>
            </div>
          </div>
          <div className="col-md-4" >

            <div className="col" >
              <br></br><br></br>
              <img src={bodyImage} className="img-fluid rounded h-75" alt="" style={{ width: '50%', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} />
            </div>
          </div>

        </div>
        {/* More Question/Answers  */}

        <section id="footer-questions" >
          <div className="container-fluid">
            <h3 style={{ fontSize: '30px', fontWeight: 'bold', color: '#353B8C' }} id="question">سوال و جواب</h3>

            <div className="wrapper center-block footer-body">
              <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                <div class="panel panel-default">
                  <div className="panel-heading" role="tab" id="headingOne">
                    <h4 className="panel-title">
                      <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <p className="text-right">میرا ڈیٹا کتنا محفوظ ہے؟</p>
                      </a>
                    </h4>
                  </div>
                  <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                    <div className="panel-body text-right body-text">
                      <p>آپ کے کاروباری ڈیٹا کی رسائی کسی بھی دوسرے صارف کو نہیں ہے- آپ کے پاس ورڈ کوئی بھی نہیں دیکھ سکتا کیونکہ پاس ورڈ ایک خاص طریقہ کار (انکریپشن) سے محفوظ کیے جاتے ہیں حتی کہ  جی منڈی ڈاٹ پی کے بنانے والے سافٹ ویئر انجینئر بھی نہیں دیکھ سکتے</p>
                    </div>
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading" role="tab" id="headingTwo">
                    <h4 className="panel-title">
                      <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <p className="text-right">میرا ڈیٹا کون کون دیکھ سکتا ہے؟</p>
                      </a>
                    </h4>
                  </div>
                  <div id="collapseTwo" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                    <div className="panel-body text-right body-text">
                      <p>آپ کا ڈیٹا صرف اور صرف آپ کے اپنے بنائے ہوئے صارفین کے اکاونٹ میں ہی قابلِ استعمال ہے</p>
                    </div>
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading" role="tab" id="headingThree">
                    <h4 className="panel-title">
                      <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        <p className="text-right"> کیاعدم ادائیگی پہ ڈیٹا ڈیلیٹ/حذف کردیا جائے گا؟</p>
                      </a>
                    </h4>
                  </div>
                  <div id="collapseThree" className="panel-collapse collapse" role="tab" aria-labelledby="headingThree">
                    <div className="panel-body text-right body-text">
                      <p>
                        آپ کا ڈیٹا عدم ادائیگی پہ فورا  ڈیلیٹ/حذف نہیں کیا جاتا بلکہ آپ کو یاد دہانی کے پیغام دئیے جائیں گے اور ۳ ماہ بعد آپ کا ڈیٹا  ڈیلیٹ/حذف کر دیا جائے گا۔ اور تین ماہ کے بعد آپ کی درخواست پر آپ کے حذف شدہ ڈیٹا کو پچھلے بیک اپ سے بحال کرنے کی کچھ (مروجہ ریٹ پر) فیس ادا کرنا ہو گی۔
                      </p>

                    </div>
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading" role="tab" id="headingFour">
                    <h4 className="panel-title">
                      <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        <p className="text-right"> کیا مجھے اپنے پُرانے کھاتے بھی جی منڈی میں ڈالنے ضروری ہیں؟</p>
                      </a>
                    </h4>
                  </div>
                  <div id="collapseFour" className="panel-collapse collapse" role="tab" aria-labelledby="headingFour">
                    <div className="panel-body text-right body-text">
                      <p>
                        جی نہیں۔ آپ جب بھی کوئی نئی پارٹی بنائیں تو اُس کا پچھلا بیلنس ڈال کر آیندہ آنے ہونے والے حساب کتاب کا تفصیلی ریکارڈ رکھ سکتے ہیں۔ آگر آپ چاہیں تو اُس کے پُرانے کھاتے ایک ایک کر کے ڈالتے جائیں۔
                      </p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
        {/* CONTACT  */}

        <section id="contact" className="mt-4">
          <div id="questions" class="container">
            <div class="section-header">
              <h2>رابطہ کریں</h2>
            </div>

            <div class="row contact-info">
              <div class="col-md-5">
                <div class="contact-address">
                  <h3>Address</h3>

                  <address>305-D,Evacuee Trust Complex, Sector F-5/1, Islamabad, 44000, Pakistan.</address>
                </div>
              </div>

              <div class="col-md-3">
                <div class="contact-phone">
                  <h3>WhatsApp</h3>
                  <p>
                    <i style={{ fontSize: '18px', color: 'green' }} class="fab fa-whatsapp"></i>
                    <a style={{ color: 'green', fontSize: '18px', paddingLeft: '5px' }} href="https://api.whatsapp.com/send?phone=923356969119&text=اسلام علیکم"><span style={{ color: '#D3C086', fontSize: '18px' }}><b> 0335 6969 119</b></span></a>
                    <p><a href="mailto:gmandi@agilevectors.com">gmandi@agilevectors.com</a></p>
                  </p>
                </div>
              </div>
              <div class="col-md-3">
                <MobileStoreButton
                  store="android"
                  url={appUrl}
                  linkProps={{ title: 'play Store Button' }}
                />
              </div>

            </div>
          </div>

        </section>

        <Fragment>
          <ScrollButton />
        </Fragment>
        <footer id="footer">
          <div class="container">
            <div class="copyright">
              &copy; Copyright 2021 <strong>
                <a style={{ color: 'white' }} href="http://www.agilevectors.com/"> Agile Vectors</a> </strong></div>

            {/* <div class="credits">
     
    </div> */}
          </div>
        </footer>

      </div>
    );
  }
}



export default IndexPage;
