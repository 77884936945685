import httpClient from "../http-client/client";

export default class CountryStateCityService {

   //get states
   getState = () => {
    return  httpClient.get("/states");
  };

   //get cities
   getCity = (id) => {
    return  httpClient.get(`/cities/${id}`);
  };

}
 