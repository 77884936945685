import React from 'react';
import { Switch, Route } from 'react-router-dom';
import IndexPage from "./views/home";
import Rates from "./views/Rates-Management/index";
import Privacy from "./views/Privacy";
const Main = () => {
  return (
    <Switch> {/* The Switch decides which component to show based on the current URL.*/}
      <Route exact path='/' component={IndexPage}></Route>
      <Route exact path='/ProductRates' component={Rates}></Route>
      <Route exact path='/privacy' component={Privacy}></Route>
    </Switch>
  );
}

export default Main;