import axios from "axios";
import { URL } from "../config/config";

const instance = axios.create({
  baseURL: URL
});

// `withCredentials` indicates whether or not cross-site Access-Control requests
// should be made using credentials

// intercept the request and add these configuration with request
instance.interceptors.request.use(
  config => {
    config.withCredentials = true;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default instance;
