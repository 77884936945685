import React, { Component } from "react";
import { Breadcrumb, BreadcrumbItem 
  // FormGroup,
  // Label,
  // Input, Button 
} from "reactstrap";
import { Link } from "react-router-dom";
import httpClient from "../../http-client/client";
// import DatePicker from "react-datepicker";
import RatesTable from "../../components/ratesTable";
import { CountryStateCityService } from '../../services';

class Rates extends Component {
  constructor(props) {
    super(props);
    this.countryStateCityServicer = new CountryStateCityService();
  }

  state = {
    fromDate: new Date(),
    cityId: '',
    data: [],
    cities: [],
    provinces: []

  };
  
  componentDidMount() {
   
   // this.getState();
    this.getProductRates();
  }
  
  getState = async () => {
    try {
      let { data } = await this.countryStateCityServicer.getState();
      this.setState({ provinces: data['response'] });
    } catch (error) {}
  };
  getCity = async id => {
    try {
      if (id === '') {
        this.setState({ cities: [] });
        return;
      }
      let { data } = await this.countryStateCityServicer.getCity(id);
      this.setState({ cities: data['response'] });
    } catch (error) {}
  };

  handlefromDate = date => {
    this.setState({
      fromDate: date
    });
  };
  getProductRates = async () => {
  

//     if( this.state.cityId==""){
// return;
//     }
    try {
      // let { cityId, fromDate } = this.state;
      // fromDate = fromDate.getTime();
      // console.log("fromDate",fromDate);

      let { data } = await  httpClient.get(`/products/product-rates`);
      


      this.setState({ data: data["response"] });
    } catch (error) {
      this.setState({ data: [] });
    }
  };

  render() {
     // console.log("data",this.state.data);
     const provinceList = this.state.provinces.map(p => (
      <option key={p['state_id']} value={p['state_id']}>
        {p['state_name']}
      </option>
    ));
    const cityList = this.state.cities.map(c => (
      <option key={c['city_id']} value={c['city_id']}>
        {c['city_name']}
      </option>
    ));

    return (
      <div>
        <Breadcrumb style={{ marginTop: "1%" }}>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>

          <BreadcrumbItem active>Product Rates</BreadcrumbItem>
        </Breadcrumb>

        {/*card*/}
        {/* <div className="mt-3">
          <div className="row text-right">
        
          <FormGroup className="col-sm-12 col-md-3">
              <Label for="fromDate" style={{ display: "block" }}>
               تاریخ  
              </Label>
              <DatePicker
                style={{ dipslay: "block"}}
               selected={this.state.fromDate}
                dateFormat="dd/MM/yyyy"
                onChange={this.handlefromDate}
              />
              
            </FormGroup>
            <FormGroup className='col-sm-12 col-md-3 '>
                  <Label for='province'>صوبہ</Label>
                  <Input
                    type='select'
                    name='province'
                    id='province'
                   
                    onChange={e => {
                      this.getCity(e.target.value);
                    }}
                  >
                    <option value=''>Select Province</option>
                    {provinceList}
                  </Input>
                </FormGroup>
            <FormGroup className="col-sm-12 col-md-3">
              <Label for="partyId">شہر کا نام</Label>
              <Input
                type="select"
               // value={partyId}
                name="cityId"
               
                id="cityId"
                onChange={(event)=>this.setState({cityId:event.target.value})}
              >
                <option value="">Select city</option>
                {cityList}
              </Input>
            </FormGroup>
           

        
            <div
              className="col-sm-12 col-md-2 mt-2"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Button color="secondary"
               onClick= {this.getProductRates}
               >
                <i className="fas fa-file-alt"></i> Search
              </Button>
            </div>
          </div>
        </div> */}
        <div className="mt-3">
          <RatesTable data={this.state.data}  getUsers={this.getUsers}/>
        </div>

     
      </div>
    );
  }
}

export default Rates;
