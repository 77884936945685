import React from "react";
import ReactDOM from "react-dom";
import "./styles/styles.scss";
import "./styles/_datepicker.scss"
import "@fortawesome/fontawesome-free/css/all.min.css";
import { BrowserRouter } from 'react-router-dom';

import App from "./App";
// import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
    (
        <BrowserRouter>
<App />
</BrowserRouter>), document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
