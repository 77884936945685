import React, { Component } from "react";
import ReactTable from "react-table";
import { Button } from "reactstrap";
import "react-table/react-table.css";
import moment from "moment";
// import AgentService from "../services";

const columns = [
  {
    Header:"تاریخ",
    accessor: "rate_date", // String-based value accessors!
    Cell: props => (
      <span className="d-flex justify-content-center"> {moment(props.value).format('DD-MM-YYYY')}</span>
    )
  },
  {
    Header: "شہر",
    accessor: "city_name", // String-based value accessors!
    Cell: props => (
      <span className="d-flex justify-content-center">{props.value}</span>
    )
  },
 
  {
    Header: "مصنوعات ",
    accessor: "product_name", // String-based value accessors!
    Cell: props => (
      <span className="d-flex justify-content-center">{props.value}</span>
    )
  },

  {
    Header: "مصنوعات اقسام",
    accessor: "product_type_name", // String-based value accessors!
    Cell: props => (
      <span className="d-flex justify-content-center">{props.value}</span>
    )
  },
  {
    Header: "آغاز  ریٹ",
    accessor: "start_rate", // String-based value accessors!
    Cell: props => (
      <span className="d-flex justify-content-center">{props.value}</span>
    )
  },
  {
    Header: " اوسط ریٹ",
    accessor: "average_rate", // String-based value accessors!
    Cell: props => (
      <span className="d-flex justify-content-center">{props.value}</span>
    )
  },
  {
    Header: "اختتامی ریٹ",
    accessor: "end_rate", // String-based value accessors!
    Cell: props => (
      <span className="d-flex justify-content-center">{props.value}</span>
    )
  },
 
];

export default class RatesTable extends Component {
  

  state = {
  //  updateUserModal: false,
    rateId: ""
  };

  componentDidMount() {
   //  this.getUsers();
  }

 
  

  getUsers = ()=>{
    this.props.getUsers();
  }

  render() {
     let { data } = this.props;

    // data.forEach(element => {


    //   element["operation"] = (
    //     <span>
    //       <Button
            

    //         onClick={() => {
    //           this.showUpdateModal(element["user_id"]);
    //         }}
    //         color="primary"
    //         className="m-1"
    //         // disabled={}
    //       >
    //         <i className="fas fa-edit"></i>
    //       </Button>
        
    //     </span>
    //   );
    // });

    return (
      <div>
        <ReactTable
          data={data}
          columns={columns}
          showPaginationTop={true}
          showPaginationBottom={false}
        />
      
     
      </div>
    );
  }
}
